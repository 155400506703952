import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { object, string } from 'prop-types';
import { observer, inject } from 'mobx-react';
import { Dashboard, useAccessibility } from '@refrens/jupiter';

import { Link, Router } from '@/router';
import { getUrl } from '@/lib/getUrl';
import { SecondaryButton, LinkButton } from '@/components/blocks/Button';
import Head from '@/components/layout/head';
import PagePaper from '@/components/blocks/PagePaper';
import EmptyState from '@/components/widgets/EmptyState';
import { CenterAlignText } from '@/components/blocks/Typography';
import JupiterStyleOverride from '@/components/styles/JupiterStyleOverride';
import getPageDetails from '@/helpers/getPageDetails';
import AutoTrial from '@/components/widgets/AutoTrial';
import getDocumentExhaustedQuota from '@/helpers/getDocumentExhaustedQuota';

const BusinessDashboard = (props) => {
  const {
    query,
    canonical,
    store,
    activeKey: initialActiveKey,
    businessSummary: initialBizSummary,
    dashboardData,
  } = props;

  const { auth } = store;

  const { businesses = [], feedNavigation = 'NONE' } = auth || {};
  const [firstBusiness] = businesses || [];

  const [activeKey, setActiveKey] = useState(initialActiveKey);
  const [dashboardVisited, setDashboardVisited] = useState(false);

  const [businessSummary, setBusinessSummary] = useState(
    initialActiveKey ? initialBizSummary : null,
  );

  const accessibility = useAccessibility();

  const getBusinessSummary = useCallback(async (urlKey) => {
    const business = await store.api.get(`/businesses/${urlKey}`);
    return business?.data;
  }, []);

  const onTabChange = useCallback(
    (urlKey) => {
      if (urlKey && urlKey !== activeKey) {
        setActiveKey(urlKey);
        if (!businessSummary[urlKey]) {
          getBusinessSummary(urlKey).then((summary) => {
            setBusinessSummary((prev) => {
              return {
                ...prev,
                [urlKey]: summary,
              };
            });
          });
        }
      }
    },
    [activeKey, businessSummary],
  );

  const showFeaturePopup = useCallback(() => {
    if (activeKey) {
      const business = businessSummary?.[activeKey];
      if (!business) {
        return;
      }
      const { featuresUnlocked = {} } = business;
      const { dashboard = false } = featuresUnlocked || {};

      if (dashboard) {
        setDashboardVisited(true);
      }
    }
  }, [activeKey, businessSummary]);

  useEffect(() => {
    /* Collapse sidebar if user doesn't have any business */
    if (!auth?.businesses || !auth?.businesses?.length) {
      store.collapseAside();
    }
  }, [auth?.businesses?.length]);

  useEffect(() => {
    onTabChange(store.business?.urlKey);
    showFeaturePopup();
  }, [store.business?.urlKey]);

  useEffect(() => {
    onTabChange(query.business);
  }, [query.business]);

  const restoreBusiness = useCallback(
    async (firstBiz) => {
      if (firstBiz?.isRemoved) {
        // first patch the business to be isRemoved false
        await store.api.patch(`/businesses/${firstBiz?.urlKey}`, {
          isRemoved: false,
        });
        await store.refreshToken();
      }

      Router.pushRoute('Settings', {
        business: firstBusiness.urlKey,
      });
    },
    [firstBusiness],
  );

  const onGoToFeed = useCallback(async () => {
    if (feedNavigation === 'SIDEBAR') {
      // set feedNavigation to LANDING on first visit
      await store.api.patch('users/me', {
        feedNavigation: 'LANDING',
      });
    }
    Router.pushRoute('UserFeed');
  }, [feedNavigation]);

  const businessProfile = businessSummary?.[activeKey];

  const { ogData } = useMemo(
    () =>
      businessProfile
        ? getPageDetails({
            business: businessProfile,
            pageName: 'Dashboard',
            includeLocationInPageTitle: false,
          })
        : { title: 'Dashboard', type: 'website' },
    [businessProfile],
  );

  const documentCount = useMemo(
    () => (businessProfile ? getDocumentExhaustedQuota(businessProfile, accessibility) : 1),
    [businessProfile, accessibility],
  );

  if (!auth) {
    return <></>;
  }

  if (!activeKey) {
    return (
      <>
        <PagePaper>
          <EmptyState
            artwork='/public/images/profile/services.png'
            dashboard='services'
            value={0}
            small
            description={
              <>
                <h2>{`Hello ${auth?.name}`}</h2>
                <b>Lets get started with Refrens</b>
              </>
            }
            cta={
              <Link route='NewBusiness' passHref>
                <SecondaryButton tag='a' size='large'>
                  Create Business
                </SecondaryButton>
              </Link>
            }
          />
          {firstBusiness && (
            <CenterAlignText>
              <LinkButton onClick={() => restoreBusiness(firstBusiness)}>
                Restore removed business updated
              </LinkButton>
            </CenterAlignText>
          )}
        </PagePaper>
      </>
    );
  }

  if (!businessProfile) {
    return <></>;
  }

  return (
    <JupiterStyleOverride>
      <Head
        title={ogData.title}
        description={ogData.description}
        og={ogData}
        canonical={canonical}
      />
      {!!dashboardVisited && <AutoTrial business={businessProfile} />}
      <Dashboard
        auth={auth}
        business={businessProfile}
        enableDemoCta={false}
        isFirstVisit={!dashboardVisited}
        documentCount={documentCount}
        lastInvoice={dashboardData?.lastInvoice}
        lastQuotation={dashboardData?.lastQuotation}
        lastExpense={dashboardData?.lastExpense}
        lastClient={dashboardData?.lastClient}
        lastLead={dashboardData?.lastLead}
        lastForm={dashboardData?.lastForm}
        onGoToFeed={onGoToFeed}
      />
    </JupiterStyleOverride>
  );
};

BusinessDashboard.propTypes = {
  store: object,
  activeKey: string,
  businessSummary: object,
  query: object,
  canonical: string,
};

const BusinessDashboardModule = inject('store')(observer(BusinessDashboard));

BusinessDashboardModule.withInitialProps = async ({ query }, store) => {
  const businessSummary = {};
  let activeKey = null;
  let dashboardData = null;

  if (store.business) {
    store.auth.businesses.forEach((business) => {
      businessSummary[business.urlKey] = null;
    });
    if (query.business) {
      activeKey = query.business;
    } else if (store.business.urlKey) {
      activeKey = store.business.urlKey;
    }

    if (activeKey) {
      const business = await store.api.get(`/businesses/${activeKey}`);
      try {
        dashboardData = await store.api.get(`/businesses/${activeKey}/business-dashboard`);
      } catch (err) {
        // noop
      }
      businessSummary[activeKey] = business?.data;
    }
  }

  return {
    query,
    businessSummary,
    activeKey,
    dashboardData: dashboardData?.data,
    selfBusiness: store.isSelfBusiness(query.business),
    canonical: activeKey && getUrl('BusinessPublicProfile', { business: activeKey }),
  };
};

export default BusinessDashboardModule;
