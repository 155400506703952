import { getAddress } from '@refrens/jupiter';

export default function getBusinessLocation(locations, onlyCityAndCountry) {
  if (
    locations &&
    locations[0] &&
    locations[0].address &&
    locations[0].address.city &&
    locations[0].address.country
  ) {
    const { country, street, state, stateCode, city, district, building } = locations[0].address;
    const address = getAddress(
      { street, state, stateCode, city, country, district, building },
      { withCityAndCountry: onlyCityAndCountry },
    );
    return address;
  }
  return null;
}
