/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

import { SecondaryButton } from '@/components/blocks/Button';

export const EmptyStateLoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 50px;
  min-height: 200px;
`;

export const BigHeading = styled.h2`
  font-size: 28px;
  line-height: 36px;
`;

export const SmallHeading = styled.h3`
  font-size: 16px;
`;

export const EmptyStateContainer = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 0 20px;
  padding-top: 20px;
  > div {
    width: 550px;
    margin: 0 auto;
  }
  img {
    max-height: 40vh;
  }
  ${BigHeading} {
    margin-top: 50px;
  }
  ${SecondaryButton} {
    margin-top: 15px;
  }
  @media (${(props) => props.theme.lydia.screen.lte.s2}) {
    div {
      width: 100%;
    }
  }
  ${(props) =>
    props.small &&
    `
    padding: 0;
    img {
      max-height: 20vh;
    }
  `}
`;
/* eslint-enable import/prefer-default-export */
